import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/Layout";
import CmsBlocks from "cmspageblocks";
import Head from "../components/Layout/Head";

// markup
const IndexPage = ({ data }) => {
  const page = JSON.parse(data.buroBorkPage.field);
  const houses = data.allBuroBorkHouse.edges;

  const { locale, title, seo_title, meta_description } = page;

  return (
    <Layout locale={locale}>
      <Head
        locale={locale}
        pageTitle={seo_title ? seo_title : title}
        pageDescription={meta_description}
      />
      <CmsBlocks content={page} houses={houses} />
    </Layout>
  );
};

export const query = graphql`
  query BuroBorkHomePage {
    buroBorkPage(slug: { eq: "/" }, locale: { eq: "nl" }) {
      field
    }
    allBuroBorkHouse {
      edges {
        node {
          id
          slug
          house_code
          field
        }
      }
    }
  }
`;

export default IndexPage;
